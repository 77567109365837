.signup-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 100px;
}

.signup-page h3{
    margin-top:120px;
    width: 100%;
    text-shadow: 2px 3px 4px rgba(18,17,19);
    font-family: 'Orbitron', sans-serif;
}

.sign-up-fieldset {
    width: 300px;
    background-color: rgba(18,17,19,0.95);
    border: 1px black solid;
    border-radius: 20px;
}

.sign-up-fieldset label {
    display: block;    
}

.signup-page input {
    width: 70%;
    height: 30px;
    border-radius: 4px;
}

.sign-up-submit {
    display: block;
    margin: auto;
    margin-top: 10px;
    height: 30px;
    width: 40%;
    border-radius: 4px;
    background-color: white;
}

.sign-up-fieldset ul {
    list-style-type: none;
    padding-left: 0;
    font-size: 15px;
}

.sign-up-submit {
    width: 40%;
    border-radius:15px;
    border: none;
    font-family: 'Orbitron', sans-serif;
}

.sign-up-submit:hover {
    background-color: #024B6E;
    cursor: pointer;
    color: white;
}

@media screen and (min-width: 320px) {
    .signup-page {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (min-width: 730px) {
    .signup-page h3 {
        margin-top: 160px;
    }

    .sign-up-fieldset {
        font-size: 20px;
        width: 400px;
    }

    .signup-page input {
        height: 40px;
        font-size: 25px;
    }
}

@media screen and (min-width: 1000px) { 
    .signup-page {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
    }

    .signup-page h3{
        margin-top: 110px;
        font-size: 30px;
    }

    .sign-up-submit {
        font-size: 16px;
        height: 40px;
    }

    .validation-error {
        font-size: 15px;
    }
}