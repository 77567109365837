  .sign-in-form {
    display: flex;
    justify-content: center;
    margin: 0;
  }
  
  .header input,label {
    margin: 5px;
  }
  
  .header input {
    height: 30px;
  }

  header button {
    border: none;
    border-radius: 15px;
    margin: 5px;
    background-color: white;
    font-family: 'Orbitron', sans-serif;
  }

  header button:hover{
    background-color: #024B6E;
    border: none;
    color: white;
    cursor: pointer;
  }
  
@media only screen and (min-width: 320px) {
  .sign-in-form {
    display: none;
  }
}
  
@media only screen and (min-width: 720px) {
  .sign-in-form {
    display: flex;
    justify-content: center;
    margin: 15px;
  }

  header button {
    font-size: 20px;
  }
  }
  
@media only screen and (min-width: 1000px) {
  .sign-in-form {
    display: inline-block;
    float: right;
    margin-top: 10px;
  }

  header button {
    float: right;
    height: 30px;
    margin:4.2px;
  }
}