* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body {
  font-size: 20px;
  color: #444;
  line-height: 1.5;
  text-align: center;
  color: white;
  background: url(./thisweek2.jpg) no-repeat fixed;
  background-size: cover;
}

nav,
footer {
  padding: 1% 0;
}
