.search-bar {
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
}

.search-bar h3 {
  font-family: 'Orbitron', sans-serif;
  text-shadow:1px 3px rgba(18,17,19,0.95);
}

.just-go {
  font-family: 'Orbitron', sans-serif;
}

.search-form {
  background-color: rgba(18,17,19,0.95);
  width: 75%;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}

.search-form input {
  margin: auto;
  margin-top: 10px;
  display: block;  
  width: 80%;
  height: 30px;
  border-radius: 3px;
}

.search-form button {
  height: 30px;
  border-radius: 10px;
  margin-top: 15px;
  width:50%;
  background-color: white;
  border: none;
  font-family: 'Orbitron', sans-serif;
}

.search-form button:hover {
  background-color: #024B6E;
  cursor: pointer;
  color: white;
}
 
.results {
  border-radius: 10px;
}

.results-li {
  list-style-type: none;
  padding-left: 0;
  margin: auto;
}

.search-li-item {
  border-radius: 25px;
  background-color: rgba(18,17,19,0.96);
}
 
#event-link-search {
  color: white;
  margin: 50px;
  hyphens: auto;
  font-size: 20px;
  margin: auto;
  font-family: 'Orbitron', sans-serif;
}

.event-photo {
  display: block;
  margin: auto;
  border-radius: 40px;
  border: 10px solid #583e88;
}

@media only screen and (min-width: 320px) {
  .results {
    margin: auto;
    font-size: 15px;
  }

  .results-li {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .search-li-item {
    margin: auto;
    margin-top: 15px;
    padding: 10px;
  }

  .search-form {
    width: 90%;
  }

  .search-form button {
    font-size: 18px;
  }

  #event-link-search {
    font-size: 18px;
    margin: auto;

  }

  .event-photo {
    width:300px;
  }
}

@media only screen and (min-width: 725px) {
  .results {
    margin: auto;
    font-size: 15px;
  }

  .search-li-item {
    width: 550px;
  }
}


@media only screen and (min-width: 1000px) {
  .search-bar {
    margin-bottom: 10px;
  }

  .search-form {
    width: 500px;
    padding: 20px;
    margin: auto;
  }

  .results {
    margin: auto;
  }
  
  .search-form button {
    font-size: 20px;
  }

  .results-li {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}
