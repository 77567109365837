header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(18,17,19,0.96);
  -webkit-box-shadow: 0 3px 5px rgba(18,17,19, 0.6);
  -moz-box-shadow: 0 3px 5px rgba(18,17,19, 0.6);
  box-shadow: 0 4px 5px rgba(18,17,19, 0.6);
}

header h1 {
  display: flex;
  justify-content: center;
  padding:10px;
  font-size: 25px;
  margin: 0;
  color: white;
  font-family: 'Orbitron', sans-serif;

}
 
header a {
  text-decoration: none;
}

.home-link h1:hover {
  color: #583e88;
  cursor: pointer;
}

.fa-grin-tongue {
  float: left;
  padding: 8px;
}

.logout {
  color: white;
  font-family: 'Orbitron', sans-serif;
}

.sign-in-link {
  color: white;
  font-family: 'Orbitron', sans-serif;
}

.sign-in-link:hover {
  color: #583e88;
  cursor: pointer;
}

.logout:hover {
  color: #583e88;
  cursor: pointer;
}

@media only screen and (min-width: 720px) {
  header h1 {
    font-size: 40px;
  }
  .sign-in-link {
    display: none;
  }
}

@media only screen and (min-width: 1000px) {
  .home-link {
    display: inline-block;
    float: left;
  }

   .sign-in-link {
    display: none;
  }

  .logout-link {
    display: inline-block;
    float: right;
    padding: 15px;
    font-size: 30px;
  }
}