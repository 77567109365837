  .all-event-page {
    display: flex;
    flex-direction: column;
  }
  
  .all-event-page h2 {
    font-family: 'Orbitron', sans-serif;
    text-shadow: 2px 3px 4px rgba(18,17,19);
    width: 100%;
  }
  
  .all-event-page ul {
    list-style-type: none;
  }
  
  .all-event-page {
    border-radius: 4px;
    font-size: 15px;
    margin: auto;
  }
  
  .event {
    background-color: rgba(18,17,19,0.96);
    padding: 5px;
    border-radius: 20px;
  }
  
  .event p {
    margin: 3px;
  }
  
  .event-link {
    display: block;
    color: white;
  }
  
  .delete-review-button {
    background-color: white;
    width: 25%;
    height: 22px;
    border-radius: 10px;
    border: none;
  }
  
  .delete-review-button:hover {
    background-color: #024B6E;
    color: white;
    cursor: pointer;
  }
  
  .event-pic {
    display: block;
    margin: auto;
    border-radius: 30px;
    border: 10px solid #024B6E;
  }

  
  @media only screen and (min-width: 320px) {
    .all-event-page {
      font-size: 15px;
      margin-top: 25px;
    }
  
    .search-events-button {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
  
    .event {
      padding: 5px;
      width: 300px;
      height: 395px;
      margin: auto;
    }
  
    .event p {
      padding: 4px;
    }
  
    .slick-slider {
      width: 352px;
      padding-right: 12px;
      margin: auto;
    }

    .slick-next {
      padding: 26px;
    }

    .slick-prev {
      padding: 14px;
    }

    .event-pic {
      width: 200px;
    }
  }
  
  @media only screen and (min-width: 725px) {
    .all-event-page {
      font-size: 18px;
      margin-top: 25px;
    }
  
    .search-events-button {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      font-size: 17px;
    }
  
    .weekday {
      width:300px;
    }
  
    .event {
      padding: 0px;
      width: 500px;
      height: 440px;
      margin: auto;
    }
  
    .event p {
      padding: 0px;
    }
  
    .slick-slider {
      width: 555px;
      margin: auto;
    }

    .event-pic {
      width: 300px;
    }
  }
  
  
  @media only screen and (min-width: 1000px) {
    .all-event-page {
      margin-top: 50px;
    }
  
    .search-events-button {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    .event {
      width: 520px;
      margin: auto;
      height: 480px;
      padding: 5px;
      border-radius: 20px;
    }
  
    .event-pic{
      width: 300px;
      height: 250px;
    }
  
    .slick-slider {
      width: 600px;    
    }
  
    .slick-next {
      padding: 50px;
    }
  }