@media only screen and (min-width: 320px) {
    .event-page {
        margin-top: 140px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        
    }
}

@media only screen and (min-width: 765px) {
    .event-page {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media only screen and (min-width: 1000px) {
    .event-page {
        display: flex;
        margin-top: 100px;
        flex-wrap: wrap;
    }
}


