.home-page {
  margin-top: 170px;
  margin: 20px;
  background-color: rgba(18,17,19,0.97);
  border-radius: 10px;
  padding: 20px;
}

.home-page h2 {
  margin: auto;
  padding: 20px;
  font-family: 'Orbitron', sans-serif;
}

.sign-up-button {
  width: 50%;
  height: 50px;
  margin: auto;
  display: block;
  font-size: 30px;
  border-radius: 30px;
  background-color: white;
  font-family: 'Orbitron', sans-serif;
}

.sign-up-button:hover {
  background-color: #024B6E;
  cursor: pointer;
  color: white;
  border: none;
}


@media screen and (min-width: 320px) {
  .home-page {
    margin-top: 130px;
  }

  .home-page h2 {
    font-size: 20px;
  }

  .home-page h3 {
    font-size: 20px;
  }

  .home-page i {
    font-size: 30px;
    padding: 20px;
  }

  .sign-up-button {
    margin-top: 20px;
    font-size: 18px;
    border-radius: 25px;
  }

  .demo-login-info-home {
    display: none;
  }

  .demo-video {
    width:300px;
    display: block;
    margin: auto;
    }
}
  
@media screen and (min-width: 720px) {
  .home-page {
    margin: 60px;
    margin-top: 240px;
  }

  .home-page h2 {
    font-size: 30px;
  }

  .home-page h3 {
    font-size: 25px;
  }

  .demo-login-info-home {
    display: block;
    border: 1px solid #024B6E;
    margin: auto;
    margin-top: 10px;
    width: 65%;
    border-radius: 20px;
  }

  .demo-video {
    width:525px;
    display: block;
    margin: auto;
  }

  .sign-up-button {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1000px) { 
  .home-page {
    margin: 160px;
  }

  .home-page i {
    font-size: 40px;
    padding: 20px;
  }

  .sign-up-button {
    width: 25%;
    height: 50px;
    font-size: 20px;
    border-radius: 30px;
  }

  .demo-login-info-home {
    margin-top: 10px;
    width: 35%;
    border-radius: 20px;
  }

  .demo-video {
    width:600px;
    display: block;
    margin: auto;
  }
}
