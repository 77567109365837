.add-event-button {
  margin:20px;
  background-color: white;
  width: 35%;
  height: 35px;
  font-size: 14px;
  border-radius: 10px;
  border: none;
}

.add-event-button:hover {
  background-color: #024B6E;
  color: white;
  cursor: pointer;
} 