.sign-in-page {
    display: flex;
    justify-content: center;
    margin-top: 150px;
} 

.sign-in-page fieldset {
    width: 80%;
    background-color: rgba(18,17,19,0.98);
    border-radius: 20px;
    border: 1px black solid;
}

.sign-in-page label {
    display: block;
}

.sign-in-page input {
    width: 60%;
    height: 30px;
    border-radius: 4px;
}

.sign-in-page button {
    display: block;
    margin: auto;
    height: 30px;
    width: 35%;
    border-radius: 4px;
    font-size: 18px;
    background-color: white;
}

.signin-button:hover {
    cursor: pointer;
}

.error p {
    font-size: 15px;
}

.demo-login-info {
    border: 1px solid #024B6E;
    margin: auto;
    margin-top: 10px;
    width: 65%;
    border-radius: 20px;
}

.demo-login-info h2 {
    font-size: 20px;
}

.demo-login-info p {
    font-size: 15px;
}

@media screen and (min-width: 1000px) { 
    .sign-in-page fieldset {
        width: 50%;
        border-radius: 20px;
        border: 1px black solid;
    }
    
    .sign-in-page label {
        display: block;
    }
    
    .sign-in-page input {
        width: 50%;
        height: 30px;
        border-radius: 4px;
    }
    
    .sign-in-page button {
        display: block;
        margin: auto;
        width: 20%;
        border-radius: 4px;
    }
}